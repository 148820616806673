.gray-block{
  background: map_get($clrs, gray);
  padding: 4rem 0 0;
  position: relative;
  z-index: 5;
}
.gray-block-inner{
  background: map_get($clrs, gray);
  position: relative;
  z-index: auto;
}