@font-face {
  font-family: 'Geometria';
  src: url('../fonts/35AEFE_0_0.eot');
  src: url('../fonts/35AEFE_0_0.eot?#iefix') format('embedded-opentype'), url('../fonts/35AEFE_0_0.woff2') format('woff2'), url('../fonts/35AEFE_0_0.woff') format('woff'), url('../fonts/35AEFE_0_0.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/35AEFE_1_0.eot');
  src: url('../fonts/35AEFE_1_0.eot?#iefix') format('embedded-opentype'), url('../fonts/35AEFE_1_0.woff2') format('woff2'), url('../fonts/35AEFE_1_0.woff') format('woff'), url('../fonts/35AEFE_1_0.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/35AEFE_2_0.eot');
  src: url('../fonts/35AEFE_2_0.eot?#iefix') format('embedded-opentype'), url('../fonts/35AEFE_2_0.woff2') format('woff2'), url('../fonts/35AEFE_2_0.woff') format('woff'), url('../fonts/35AEFE_2_0.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Geometria';
  src: url('../fonts/35AEFE_3_0.eot');
  src: url('../fonts/35AEFE_3_0.eot?#iefix') format('embedded-opentype'), url('../fonts/35AEFE_3_0.woff2') format('woff2'), url('../fonts/35AEFE_3_0.woff') format('woff'), url('../fonts/35AEFE_3_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Icons';
  src:  url('../fonts/icomoon-0.eot');
  src:  url('../fonts/icomoon-0.eot#iefix') format('embedded-opentype'),
  url('../fonts/icomoon-0.ttf') format('truetype'),
  url('../fonts/icomoon-0.woff') format('woff'),
  url('../fonts/icomoon-0.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
