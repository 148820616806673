.news-element{
  border-radius: 10px;
  display: flex;
  padding: 1rem 1.5rem;
  background: #fff;
  margin-bottom: 1rem;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  &__left{
    flex-shrink: 0;
    flex-grow: 0;
    border-right: 1px solid $hr-border-color;
    padding-right: 1rem;
    min-width: 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 0 0.5rem;
      border-bottom: 1px solid $hr-border-color;
      border-right: 0px;
    }
  }
  &__right{
    padding-left: 1rem;
    @include media-breakpoint-down(md) {
      padding: 0.5rem 0 0;
      font-size: map-get($fz, 10) !important;
      * {
        font-size: map-get($fz, 10) !important;
      }
    }
  }
}