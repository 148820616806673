#slide-block{
  overflow-x: hidden;
  .slide-rell{
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0);
    cursor: grab;
    iframe{
      width: 100%;
      height: 20rem;
      border-radius: 5px;
      box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.25);
      margin-bottom: 1.5rem;
    }
    .img-element{
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
      box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.25);
      margin-bottom: 1.5rem;
    }
    .slide-element{
      padding: 2rem 15px 0;
      flex: 0 0 40%;
      display: inline-flex;
      flex-direction: column;
      box-sizing: border-box;
      @include media-breakpoint-down(md) {
        flex: 0 0 88%;
      }
      &__img{
        position: relative;
      }
      &__view{
        position: absolute;
        bottom: 3rem;
        right: 1rem;
        background-image: url('/spn-main-landing/img/zoom.svg');
        width: 46px;
        height: 46px;
      }
      &__info{
        white-space: normal;
        &__text{
          font-size: map_get($fz, 15);
          margin: 0.5rem 0 1rem 0;
          @include media-breakpoint-down(md) {
            font-size: map_get($fz, 13);
          }
        }
      }
    }
  }
}
