@keyframes slideUp {
  0% {
    transform: translateY(30%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scaleDown {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.animation {
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -ms-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.long-animation {
  -webkit-transition: all 500ms ease-out;
  -moz-transition: all 500ms ease-out;
  -ms-transition: all 500ms ease-out;
  -o-transition: all 500ms ease-out;
  transition: all 500ms ease-out;
}