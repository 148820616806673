#coop-block{
  .white_arrows {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: stretch;
    min-width: 930px;
    &__block{
      padding: 1rem;
      text-align: center;
      flex-basis: 100%;
      justify-content: center;
      flex-direction: column;
    }
    &__angle {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        background-image: url(/spn-main-landing/img/angle.svg);
        height: 100%;
        width: 10px;
        top: 0;
        right: -3px;
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
      }
    }
  }
  .white_overflow{
    overflow: auto;
  }
}