:root {
  --vh: 1vh;
}
$font-family: "Geometria", "Arial", "Helvetica Neue", sans-serif;

$clrs: (
  red: #DE1D25,
  yellow: #ffb138,
  gray: #e0dfdf,
  gray-light: #F9F9F9,
  gray-dark: #9f9f9f,
  black: #191919,
  black-light: #636363,
);

$fz: (
  9: 0.5625rem,  // 9 px
  10: 0.625rem,  // 10 px
  13: 0.8125rem,  // 13 px
  15: 0.9375rem,
  18: 1.125rem,
  20: 1.275rem,
  22: 1.375rem,
  29: 1.8125rem,
);

.txt{
  @each $name, $value in $fz {
    &__#{$name}{
      font-size: $value !important;
      @if $name == 29 {
        @include media-breakpoint-down(md) {
          font-size: map_get($fz, 22) !important;
        }
      }
    }
  }
  @each $name, $value in $clrs {
    &__color_#{$name}{
      color: $value !important;
    }
  }
}
.bg{
  @each $name, $value in $clrs {
    &__color_#{$name}{
      background-color: $value !important;
    }
  }
}