select,
input,
textarea, .inputlike{
  font-family: $font-family;
  font-size: map_get($fz, 18)!important;
  background: map_get($clrs, gray)!important;
  color: map_get($clrs, black)!important;
  border: 0!important;
  box-sizing: border-box;
  padding: 0.8rem 1rem 0.7rem!important;
  outline: none;
  min-height: 2rem!important;
  line-height: 1.2rem!important;
  border-radius: 0!important;
  text-align: left;
  min-width: 2rem;
  outline: none!important;
  box-shadow: none;
  &.white{
    border-color: #fff;
    color: #fff;
    background: transparent;
    &::placeholder{
      color: #fff;
    }
  }
}
label{
  position: relative;
  padding-left: 1rem;
  &.required{
    &:before {
      display: inline-block;
      position: absolute;
      content: '';
      top: .375rem;
      left: .25rem;
      height: .375rem;
      width: .375rem;
      border-radius: 50%;
      background-color: map-get($clrs, red);
    }
  }
}
.w-100 {
  select,
  input,
  textarea, .inputlike{
    width: 100%;
  }
}

.inputlike {
  input {
    border: 0;
    padding: 0;
    min-height: 0;
    line-height: 1rem;
  }
}

.select_wrapper {
  position: relative;
  select {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 2rem;
    cursor: pointer;
  }
  &:after {
    font-family: icomoon;
    content: "\e90a";
    position: absolute;
    right: 0.8rem;
    pointer-events: none;
    font-size: 5.5px;
    top: 50%;
    margin-top: 2px;
    line-height: 0;
    color: map_get($clrs, black);
  }
}
