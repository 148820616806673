.footer{
  background-color: map-get($clrs, black-light);
  background-image: url('/spn-main-landing/img/spn24-dark.svg');
  background-position: left -2% bottom -1%;
  background-repeat: no-repeat;
  padding: 6rem 0 2.5rem;
  background-size: 60%;
  margin-top: -3rem;
  position: relative;
  z-index: 1;
}

.footer-icon{
  height: 42px;
  width:  42px;
  @media (max-width: 991px) {
    height: 27px;
    width:  27px;
  }
}

.fixed-right{
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    width: 100%;
    left: 0;
  }
}

.yellow-left{
  position: fixed;
  left: 2rem;
  bottom: 2rem;
  z-index: 11;
  overflow: hidden;
  padding: 2rem 2rem 1rem;
  background: map-get($clrs, yellow);
  &__cross{
    position: absolute;
    right: 2rem;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    left: 0;
    bottom: 0;
  }
}
