.inner{
  width: 100vw;
  .head{
    height: auto!important;
    padding-bottom: 50px!important;
  }
}

.head{
  font-weight: normal;
  position: relative;
  //background: #fff;
  z-index: auto;

  @include media-breakpoint-down(sm) {
    height: auto;
    padding-bottom: 4rem;
  }
  .fixed-holder{
    height: 90vh;
    height: calc(var(--vh, 1vh) * 90);
    display: flex;
    flex-direction: column;
    z-index: -1;
    @include media-breakpoint-up(md) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &__top-line{
    padding: 2rem 0 2rem;
    flex-grow: 0;
    flex-shrink: 0;
  }
  &__content{
    flex: 1;
    flex-direction: column;
    display: flex;
    .block{
      flex: 1;
      display: flex;
      align-items: stretch;
    }
    .line{
      flex-shrink: 0;
      padding-bottom: 1rem;
    }
    hr{
      margin-top: 3rem;
      flex-shrink: 0;
      width: 100%;
    }
    .heading {
      @include media-breakpoint-up(md) {
        transform: translateY(40%);
        opacity: 0;
        &.active {
          animation-name: slideUp, slideOpacity;
          animation-duration: 1400ms, 3000ms;
          animation-delay: 0ms;
          animation-timing-function: ease-in;
          animation-fill-mode: forwards;
        }
      }
      .img{
        overflow: hidden;
        img {
          min-height: 68vh;
          min-height: calc(var(--vh, 1vh) * 68);
        }
      }
    }
  }
  &__added {
    p{
      text-transform: uppercase;
      font-weight: 300;
      @include media-breakpoint-up(md) {
        transform: translateY(40%);
        opacity: 0;
        &.active {
          animation-name: slideUp, slideOpacity;
          animation-duration: 1400ms, 3000ms;
          animation-delay: 0ms;
          animation-timing-function: ease-in;
          animation-fill-mode: forwards;
        }
      }
    }
  }
  .logo-info{
    @include media-breakpoint-down(md) {
      display: block;
      font-size: map-get($fz, 10);
    }
  }
}
.holiday {
  position: fixed;
  width:2rem;
  right: 0;
  top:20vh;
  display: inline-block;
  z-index: 6;

  -moz-transform: rotate(90deg); /* Для Firefox */
  -ms-transform: rotate(90deg); /* Для IE */
  -webkit-transform: rotate(90deg); /* Для Safari, Chrome, iOS */
  -o-transform: rotate(90deg); /* Для Opera */
  transform: rotate(90deg);
  &__block {
    background: #ececec;
    width: fit-content;
    position: relative;
    height: 32px;
    line-height: 1.7;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;

    img{
      width: 34px;
      height: 35px;
      background-color: transparent;
      display: flex;
      position: absolute;
    }
  }
  &__link {
    padding-left: 45px;
    font-weight: 500;
    //font-weight: bold;
  }
}

#holiday-modal .modal-body, #maintenance-modal .modal-body {
  padding: 0;
  button {
    font-size: 1.5rem;
    top: 2rem;
    right: 2rem;
    position: absolute;
  }
  .icon_Small_Close{
    font-size: 1.5rem;
    font-family: Icons!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    &:before{
      content: "\e93f";
      color: #000000;
      -webkit-box-shadow: 0 0 3px 2px rgba(255,255,255,1);
      -moz-box-shadow: 0 0 3px 2px rgba(255,255,255,1);
      box-shadow: 0 0 3px 2px rgba(255,255,255,1);
      background-color: #fff;
    }
  }
  img {
    width: 100%;
    border: 0;
  }
}

.bottom-part {
  background: map_get($clrs, gray-light);
  border-top: 1px solid $hr-border-color;
  border-bottom: 1px solid $hr-border-color;
  width: 100vw;
  position: absolute;
  bottom: 0;
  transform: translateY(40%);
  opacity: 0;
  &.active{
    animation-name: slideUp, slideOpacity;
    animation-duration: 1000ms, 2000ms;
    animation-delay: 0ms;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }
  a {
    padding: 1rem;
    display: block;
    @extend .animation;
    &:hover, &.active{
      background: map_get($clrs, red);
      color: #fff!important;
      *{
        color: #fff!important;
      }
    }
  }


  &.fixed {
    position: fixed;
    top: 0;
    bottom: auto;
    z-index: 10;
  }
  .container {
    @include media-breakpoint-down(lg) {
      max-width: none;
      padding: 0;
    }
    .row {
      .col-md-3{
        @include media-breakpoint-down(lg) {
          padding: 0;
        }
      }
    }
  }
}
