#start-block{
  .collapse-control{
    background: #fff;
    &__toggle{
      border-top: 2px solid map_get($clrs, gray);
      position: relative;
      @include media-breakpoint-down(md) {
        padding: 1rem;
      }
      .number{
        color: map-get($clrs, gray-dark);
        display: inline-block;
        margin: 0 1rem;
        width: 2rem;
        text-align: center;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      &:after{
        content: url('/spn-main-landing/img/chevron.svg');
        position: absolute;
        right: 2rem;
        @extend .animation;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      &.collapsed {
        &:after{
          transform: rotate(180deg);
        }
      }
    }
    &__body > p{
      padding: 1rem 4rem;
      margin: 0;
      @include media-breakpoint-down(md) {
        padding: 1rem;
      }
    }
  }
}