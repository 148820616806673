
@import "~bootstrap/scss/bootstrap";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";
@import "variables.scss";
@import "fonts.scss";

@import "modules/buttons.scss";
@import "modules/inputs.scss";
@import "modules/animations.scss";
@import "footer.scss";

@import "modules/head.scss";

@import "modules/white-block.scss";
@import "modules/gray-block.scss";
@import "modules/slide-block.scss";
@import "modules/pult-block.scss";
@import "modules/news-element.scss";
@import "modules/start-block.scss";
@import "modules/question-block.scss";
@import "modules/coop-block.scss";
@import "modules/inner-block.scss";
@import "modules/authorization.scss";


h1{
  font-size: map_get($fz, 29) !important;
  font-weight: normal;
  margin-bottom: 1rem!important;
  @include media-breakpoint-down(md) {
    font-size: map_get($fz, 22) !important;
  }
}

h2{
  font-size: map_get($fz, 22) !important;
  font-weight: normal;
  margin-bottom: 1rem!important;
}

h3{
  font-size: map_get($fz, 18) !important;
  font-weight: normal;
  color: map_get($clrs, black-light) !important;
}
.swal-text {
  font-size: map_get($fz, 18) !important;
  color: map_get($clrs, black) !important;
  text-align: center;
  font-weight: 300;
}
.swal-button-container{
  display: block;
  .swal-button{
    width: 100%;
  }
}
.swal-modal{
  border-radius: 0;
  position: relative;
  &:after{
    content: '';
    background: url(/spn-main-landing/img/login-triangle.svg);
    width: 52px;
    height: 100%;
    display: block;
    position: absolute;
    right: -51px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    top: 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
body {
  font-family: $font-family;
  background: map-get($clrs, gray-light);
  font-size: map-get($fz, 18);
  color: map-get($clrs, black);
  font-weight: 300;
  line-height: 1.3;
}

a {
  color: map-get($clrs, red);
  text-decoration: underline;
  background-color: transparent;
  outline: none;
  &:hover{
    color: darken(map-get($clrs, red), 10);
    text-decoration: none;
  }
}


.object-fit-cover{
  object-fit: cover;
}

.load-commerce{
  display: flex;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  background-color: #f9f9f9;
  margin-top: 1px;
  img {
    width: 3rem;
    margin: 0 1rem;
  }
}

.heading{
  position: relative;
  &__holder {
    display: flex;
    height: 100%;
    align-items: flex-end;
    div.img{
      height: 100%;
      flex: 1;
      position: relative;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      &:before{
        position: absolute;
        z-index: 1;
        content: "";
        background-image: url('/spn-main-landing/img/heading.svg');
        background-repeat: no-repeat;
        width: calc(100% + 16px);
        height: calc(100% + 2px);
        background-size: 100%;
        margin: -1px -8px 0;
      }
    }
    span {
      flex-shrink: 0;
      writing-mode: vertical-rl;
      text-orientation: sideways;
      color: map_get($clrs, black-light);
      font-size: map_get($fz, 13);
      transform: rotate(-180deg);
      padding: 0 0.5rem 0 1rem;
    }
  }


  &-white{
    div.img{
      &:before{
        background-image: url('/spn-main-landing/img/heading-white.svg');
      }
    }
  }
}

body{
  width: 100vw;
  overflow-x: hidden;
  .popup-block{
    @extend .long-animation;
    opacity: 0;
    padding: 5rem 0;
    display: none;
    margin-top: 0rem;
    background: rgba(0,0,0,.5);
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 12;
    align-items: center;
    justify-content: center;
    overflow: scroll;
    cursor: pointer;
    &__holder{
      cursor: default;
      @extend .long-animation;
      opacity: 0;
      margin-top: -5rem;
      transform: scale(0.9);
    }
  }
  &.popup{
    overflow: hidden;
    .popup-block{
      opacity: 1;
      display: flex!important;
      &__holder{
        opacity: 1;
        transform: scale(1);
        max-width: calc(100vw - 30px);
        margin: auto;
        position: relative;
        .close{
          position: absolute;
          content: url('/spn-main-landing/img/close.svg');
          top: -3rem;
          left: 0;
        }
      }
    }
  }
}

.form-control:focus {
  border-color: map-get($clrs, red);
  box-shadow: 0 0 0.1rem 0.1rem transparentize(map-get($clrs, red), .7);
}

.collapse-control{
  &__toggle{
    cursor: pointer;
    color: map-get($clrs, red);
    padding: 0.8rem 0;
    border-top: 1px solid map-get($clrs, gray-dark);
    &:first-child {
      border-top: 0px;
    }
  }
  &__body{

  }
}


ol, ul{
  padding-left: 1.5rem;
  li{
    font-size: map_get($fz, 18);
    color: map_get($clrs, black-light);
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    p {
      margin-bottom: 0.5rem;
    }
  }
  ol, ul {
    margin-top: 0.5rem;
    li {
      margin-top: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .h-md-100 {
    height: 100% !important;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 22px;
    background: map-get($clrs, red);
  }
  ::-webkit-scrollbar-track {
    border-radius: 0;
    background: transparent;
  }
}


@include media-breakpoint-down(md) {
  .row-scroll {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translateZ(0);
    &>* {
      display: inline-block;
      vertical-align: top;
    }
    &.row-visible-next>div {
      width: 88%;
      max-width: 88%;
      flex: 0 0 88%;
      white-space: normal;
    }
  }
}
@include media-breakpoint-down(sm) {
  .sm-no-container{
    max-width: 100%;
  }
}

.showbuttons{
  display: none;
}

.pb-15,
.py-15 {
  padding-bottom: 15rem !important;
}

#youtube-block iframe {
  width: 100%;
  height: 46vw;
  border-radius: 10px;
}

@include media-breakpoint-up(lg) {
  #youtube-block iframe {
    height: 623px;
  }
}

.padding-top-4rem {
  padding-top: 4.2rem
}

.close {
  opacity: 1;
}
.logoimg {
  width: 140px; /* Укажите нужную ширину */
  height: 70px; /* Укажите нужную высоту */
  object-fit: contain; /* Сохранение пропорций изображений */
}
.sk-logo {
  height: 60px;
}
