.authorization{
  display: flex;
  .eye-animation{
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
  }
  &__l-part{
    background: #fff;
    padding: 3rem 2rem 3rem 4rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    position: relative;
    @include media-breakpoint-down(md)  {
      padding: 2rem 1rem;
      border-radius: 0;
    }
  }
  &__r-part{
    position: relative;
    flex: 0 0 auto;
    img {
      height: 100%;
      margin-left: -1px;
      user-select: none;
    }
    @include media-breakpoint-down(md)  {
      display: none;
    }
  }
}