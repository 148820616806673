.btn, .swal-button{
  white-space: nowrap;
  display: flex;
  align-items: center;
  border: 0;
  font-size: map_get($fz, 13);
  border-radius: 0;
  color: #fff;
  background: map_get($clrs, red);
  border: 1px solid map_get($clrs, red);
  padding: 0.8rem 1rem 0.7rem;
  line-height: 1.2rem!important;
  text-decoration: none;
  justify-content: center;
  text-transform: uppercase;
  box-shadow: none!important;
  img{
    margin-top: -3px;
  }
  &:hover{
    background: darken(map-get($clrs, red), 10)!important;
    color: #fff!important;
  }
  &__round{
    border-radius: 5rem;
    border: 1px solid map-get($clrs, red);
    background: transparent;
    padding: 8px 15px 7px 21px;
    color: map_get($clrs, black);
    &:hover{
      background: map_get($clrs, red)!important;
      color: #fff!important;
    }
  }
}