#pult-block{
  .container{
    background: map_get($clrs, black-light);
    border-radius: 10px;
  }
  .coda-block{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5rem;
    background: #fff;
  }
}
