.inner-block{
  background: #fff;
  margin: 30rem 0 -10rem;
  padding: 4rem 7rem;
  z-index: 1;
  position: relative;
  top: -10rem;
  @include media-breakpoint-down(md) {
    padding: 2rem 15px;
  }
}

.inner-white{
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: -10rem;
  width: 45vw;
  height: 100%;
  max-width: 100%;
  z-index: 1;
  margin: auto;
}