.inner{
  .white-block{
    margin-top: 0!important;
  }
}


.white-block{
  background: #fff;
  font-weight: normal;
  position: relative;
  z-index: 5;
  @include media-breakpoint-up(md) {
    margin-top: 100vh;
  }
  &__content{
    max-width: 57rem;
    margin: 0 auto;
    padding: 6rem 1rem 5rem;
    @include media-breakpoint-down(md) {
      padding-left: 15px;
      padding-right: 15px;
    }
    .to-label{
      position: relative;
      bottom: -16.5rem;
      z-index: 1;
      margin-top: -9rem;
      min-height: 6rem;
      @include media-breakpoint-down(md) {
        margin-top: 1rem;
        bottom: 0;
      }
    }
  }
  &__bottom{
    position: relative;
    overflow: hidden;
    width: 100%;
    img {
      width: 104%;
      margin: auto;
      left: -2%;
      position: relative;
      margin-bottom: -1%;
    }
  }

  .cover-img{
    width: 100%;
    height: 700px;
    object-fit: cover;
    background: map_get($clrs, gray);
  }
}

.animate-smaller{
  position: fixed;
  width: 100vw;
  height: 700px;
  top: 0;
  z-index: -1;
}